import React from "react";

const getStatusColor = (status) => {
  switch (status) {
    case "Pending":
      return "bg-yellow-100 text-yellow-600";
    case "Completed":
      return "bg-green-100 text-green-600";
    case "Draft":
      return "bg-blue-100 text-blue-600";
    case "Archived":
      return "bg-gray-100 text-gray-600";
    case "Scheduled":
      return "bg-purple-100 text-purple-600";
    case "Expired":
      return "bg-red-100 text-red-600";
    default:
      return "";
  }
};

const ContractStatus = ({ status }) => {
  const color = getStatusColor(status);
  return (
    <span
      className={`inline-flex items-center rounded-md ml-2 px-2 py-0.5 text-xs font-medium text-gray-600 ${color}`}
    >
      {status}
    </span>
  );
};

export default ContractStatus;
